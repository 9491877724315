export default [
  {
    header: 'Personal buckets',
  },
  {
    title: 'My Roles',
    route: 'my-role-list',
    icon: 'AwardIcon',
    resource: 'myRole',
    action: 'read',
  },
  {
    title: 'My Profile',
    route: 'my-profile',
    icon: 'UserIcon',
    resource: 'myProfile',
    action: 'read',
  },
  {
    title: 'My Processes',
    route: 'my-process-list',
    icon: 'ToolIcon',
    resource: 'myProcess',
    action: 'read',
  },
  {
    title: 'My Tasks',
    route: 'my-task-list',
    icon: 'CheckSquareIcon',
    resource: 'myTask',
    action: 'read',
  },
]
