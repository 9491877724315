export default [
  {
    header: 'Tools',
  },
  {
    title: 'Matrix View',
    route: 'matrix-list',
    icon: 'GridIcon',
    resource: 'tool',
    action: 'read',
  },
  {
    title: 'Filters',
    route: 'filter-list',
    icon: 'filter',
    resource: 'tool',
    action: 'read',
  },
  {
    title: 'Labels',
    route: 'label-list',
    icon: 'TagIcon',
    resource: 'tool',
    action: 'read',
  },
  {
    title: 'Process Categories',
    route: 'process-category-list',
    icon: 'BookmarkIcon',
    resource: 'tool',
    action: 'read',
  },
  {
    title: 'Settings',
    route: 'setting-list',
    icon: 'SettingsIcon',
    resource: 'tool',
    action: 'read',
  },
]
