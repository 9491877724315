export default [
  {
    header: 'Organization buckets',
  },
  {
    title: 'Roles',
    route: 'role-list',
    icon: 'AwardIcon',
    resource: 'role',
    action: 'read',
  },
  {
    title: 'People',
    route: 'user-list',
    icon: 'UsersIcon',
    resource: 'user',
    action: 'read',
  },
  {
    title: 'Processes',
    route: 'process-list',
    icon: 'ToolIcon',
    resource: 'process',
    action: 'read',
  },
  {
    title: 'Tasks',
    route: 'task-list',
    icon: 'CheckSquareIcon',
    resource: 'task',
    action: 'read',
  },
]
