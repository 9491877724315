<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-bind="edit(linkProps)" class="d-flex align-items-center">
      <b-icon-filter v-if="item.icon === 'filter'" />
      <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
  import { mapState } from 'vuex'
  import { useUtils as useAclUtils } from '@core/libs/acl'
  import { BLink, BBadge, BIconFilter } from 'bootstrap-vue'
  import { useUtils as useI18nUtils } from '@core/libs/i18n'
  import { $themeConfig } from '@themeConfig'
  import useVerticalNavMenuLink from './useVerticalNavMenuLink'
  import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

  export default {
    components: {
      BLink,
      BBadge,
      BIconFilter,
    },
    mixins: [mixinVerticalNavMenuLink],
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState('user', ['currentUser']),
    },
    methods: {
      edit(link) {
        if (link.to.name === 'my-profile') {
          const to = `../myProfile/${this.currentUser.id}`
          return { to }
        }
        return link
      },
    },
    setup(props) {
      const { wrench } = $themeConfig.icons
      const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
        props.item
      )
      const { t } = useI18nUtils()
      const { canViewVerticalNavMenuLink } = useAclUtils()

      return {
        isActive,
        linkProps,
        updateIsActive,
        wrench,

        // ACL
        canViewVerticalNavMenuLink,

        // i18n
        t,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .logo {
    height: 20px;
    width: 20px;
    margin-right: 14px;
  }
  .white-filter {
    filter: brightness(0) invert(1);
  }
</style>
