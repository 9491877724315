export default [
  {
    header: 'Dashboards',
  },
  {
    title: 'Organization',
    route: 'dashboard-ecommerce',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Personal',
    route: 'dashboard-personal',
    icon: 'HomeIcon',
    resource: 'myDashboard',
    action: 'read',
  },
]
